<template>
    <section class="px-3">
        <div class="row mx-0 my-3">
            <div class="col-auto px-0">
                <div class="bg-white cr-pointer br-12 shadow py-2 px-4 f-18" @click="crearEntrega()">
                    Crear Entrega
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-4 f-14">
            <draggable
            v-model="entregas"
            tag="ul"
            class="list-group"
            handle=".handle"
            item-key="name"
            @change="cambioPosicion"
            >
                <div v-for="(e, index) in entregas" :key="index" class="card-entregas d-flex col-12 pr-0 pl-0  my-2">
                    <div class="draggable d-middle px-1 border-right">
                        <i class="icon-drag text-general f-20 cr-pointer handle" />
                    </div>
                    <div class="content w-100">
                        <div class="row mx-0">
                            <p class="col f-600 text-gris py-1 f-17 tres-puntos-doblelinea">
                                {{ e.titulo }}
                            </p>
                            <div class="col-auto ml-auto">
                                <i class="icon-delete text-general f-20 cr-pointer" @click="modalEliminarEntrega(e.id)" />
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 my-2 text-break">    
                                {{ e.descripcion }}
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
        <div v-if="entregas.length === 0">
            <sin-datos mensaje="Aún no se han creado entregas." />
        </div>

        <!-- Partials -->
        <modal-crear-entregas ref="CrearEntrega" @update="listarEntregas" />
        <modal-eliminar 
        ref="EliminarEntregaModal" 
        titulo="¿Está seguro de eliminar esta entrega?" 
        alerta="Los datos de esta entrega y la informacion no podran ser recuperados"
        :eliminar="true"
        @eliminar="eliminarEntrega"
        />
    </section>
</template>

<script>
import Cursos from '~/services/cursos/gestionar'
export default {
    components: {
        modalCrearEntregas: () => import('../../partials/modalCrearEntrega')
    },
    data(){
        return { 
            entregas: [],
            id_entrega_eliminar:null
        }
    },
    computed:{
        id_curso(){
            return Number(this.$route.params.id_curso);
        }
    },
    mounted(){
        this.listarEntregas()
    },
    methods: {
        
        async listarEntregas(){
            try {
                const {data} = await Cursos.listarEntregas(this.id_curso)
                this.entregas = data.data.entregas


            } catch (error){
                this.error_catch(error)
            }
        },  
        crearEntrega(){
            this.$refs.CrearEntrega.toggle();
        },
        async cambioPosicion({moved}){
            try {
                let model = {
                    nuevaPosicion:moved.newIndex + 1,
                    antiguaPosicion:moved.oldIndex + 1
                }
                
                const {data} = await Cursos.updatePosicionEntrega(this.id_curso,model)
                this.notify('Éxito, Posición Actualizada','success')

            } catch (error){
                this.listarEntregas()
                this.error_catch(error)  
            }
        },
        async eliminarEntrega(){
            try {
                
                await Cursos.eliminarEntrega(this.id_entrega_eliminar)
                this.notify('Eliminado, Registro removido correctamente','success')
                this.$refs.EliminarEntregaModal.toggle();
                this.id_entrega_eliminar = null
                this.listarEntregas()


            } catch (error){
                this.error_catch(error) 
            }
        },
        modalEliminarEntrega(id_entrega){
            this.id_entrega_eliminar = id_entrega
            this.$refs.EliminarEntregaModal.toggle();
        },
        
    }
}
</script>

<style lang="scss" scoped>
.card-entregas{
    border: 1px solid #DFDFDF !important;
    min-height: 140px;
    border-radius: 12px !important;
}
</style>